.cardhome{
    width: 50%;
  }
  
  
  .colorbtn{
      color: #e4e4e4;
      background-color: #11388a !important;
  }
  .colorbtn:hover {
      color: white !important;
    }
  
  .colorh3{
      color: #1f4591;
  }
  .colorh3sub{
    color: #4e4e4e;
  }
  
  /* ------------------------------------ */
  * {
      margin: 0px; 
      padding: 0px; 
      box-sizing: border-box;
  }
  
  
  a {
      margin: 0px;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }
  
  a:focus {
      outline: none !important;
  }
  
  a:hover {
      text-decoration: none;
  }
  
  /* ------------------------------------ */
  h1,h2,h3,h4,h5,h6 {margin: 0px;}
  
  p {margin: 0px;}
  
  ul, li {
      margin: 0px;
      list-style-type: none;
  }
  button {
      outline: none !important;
      border: none;
      background: transparent;
  }
  
  button:hover {
      cursor: pointer;
  }
  
  iframe {
      border: none !important;
  }
  
  .limiter {
    width: 100%;
    margin: 0 auto;
  }
  
  .container-table100 {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .wrap-table100 {
    width: 1170px;
  }
  
  table {
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  table * {
    position: relative;
  }
  table td, table th {
    padding-left: 8px;
  }
  table thead tr {
    height: 60px;
    background: #1f4591;
  }
  table tbody tr {
    height: 50px;
  }
  table tbody tr:last-child {
    border: 0;
  }
  table td, table th {
    text-align: left;
  }
  table td.l, table th.l {
    text-align: right;
  }
  table td.c, table th.c {
    text-align: center;
  }
  table td.r, table th.r {
    text-align: center;
  }
  
  
  .table100-head th{
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f5f5f5;
  }
  
  tbody tr {
    font-size: 15px;
    color: #808080;
    line-height: 1.2;
  }
  
  tbody tr:hover {
    color: #555555;
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .column1 {
    width: 140px;
    padding-left: 40px;
  }
  
  .column2 {
    width: 260px;
  }
  
  .column3 {
    width: 260px;
  }
  
  .column4 {
    width: 110px;
    text-align: center;
  }
  
  .column5 {
    width: 170px;
    text-align: center;
  }
  
  .column6 {
    width: 110px;
    text-align: center;
  }
  
  
  @media screen and (max-width: 992px) {
    table {
      display: block;
    }
    table > *, table tr, table td, table th {
      display: block;
    }
    table thead {
      display: none;
    }
    table tbody tr {
      height: auto;
      padding: 37px 0;
    }
    table tbody tr td {
      padding-left: 40% !important;
      margin-bottom: 24px;
    }
    table tbody tr td:last-child {
      margin-bottom: 0;
    }
    table tbody tr td:before {
      font-size: 14px;
      color: #292929;
      line-height: 1.2;
      position: absolute;
      width: 40%;
      left: 30px;
      top: 0;
    }
    table tbody tr td:nth-child(1):before {
      content: "Código";
    }
    table tbody tr td:nth-child(2):before {
      content: "Nombre";
    }
    table tbody tr td:nth-child(3):before {
      content: "Correo E.";
    }
    table tbody tr td:nth-child(4):before {
      content: "Ult. Visita";
    }
    table tbody tr td:nth-child(5):before {
      content: "Ver Más";
    }
    table tbody tr td:nth-child(6):before {
      content: "Editar";
    }
  
    .column4,
    .column5,
    .column6 {
      text-align: left !important;
      justify-items: left !important;
      display: flex !important;
    }
  
    .column4,
    .column5,
    .column6,
    .column1,
    .column2,
    .column3 {
      width: 100%;
    }
  
    tbody tr {
      font-size: 14px;
    }
  }
  
  @media (max-width: 576px) {
    .container-table100 {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

.table-spinner-container {
  position: sticky;
  top: 1%;
  left: 50%;
  z-index: 1;
  text-align: center;
  height: 1px;

  .spinner-border {
    margin-top: 78px;
  }
}